<template>
  <div>
    <a-modal v-model="modalVisible" :title="modalTitle" :width="1250" :centered="true" :body-style="modalBodyStyle">
      <template slot="footer">
        <a-button @click="modalVisible=false">{{showType=='detail' ? '关闭' : '取消'}}</a-button>
        <a-button v-if="showType=='add' || showType=='edit'|| showType=='paste'" type="primary" @click="confirm()">提交</a-button>
      </template>
      <a-form-model ref="modalForm" layout="inline" class="form-in-twocolumns" :model="formData" :rules="formRules" :label-col="{span:6}" :wrapper-col="{span:16}">
        <a-form-model-item label="项目名称" prop="monitorpointnum">
          <a-select :disabled="showType=='detail'" v-model="formData.monitorpointnum" placeholder="请选择" show-search :filter-option="filterOption">
            <a-select-option v-for="(item, index) in monitorpointList" :key="index" :value="item.monitorpointnum">{{item.monitorpointname}}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="防治区域" prop="control_area">
          <a-select :disabled="showType=='detail'" v-model="formData.control_area">
            <a-select-option value="沟渠">沟渠</a-select-option>
            <a-select-option value="草坪">草坪</a-select-option>
            <a-select-option value="室外">室外</a-select-option>
            <a-select-option value="室内">室内</a-select-option>
            <a-select-option value="场所">场所</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="位置" prop="position">
          <a-input :disabled="showType=='detail'" v-model.trim="formData.position" />
        </a-form-model-item>
        <a-form-model-item label="数量" prop="quantity">
          <a-input :disabled="showType=='detail'" v-model.trim="formData.quantity" suffix="平方米"/>
        </a-form-model-item>
        <a-form-model-item label="要求" prop="requirement">
          <textarea :disabled="showType=='detail'" v-model.trim="formData.requirement"  style="width: 390px"/>
        </a-form-model-item>
        <a-form-model-item label="备注" prop="remark">
          <textarea :disabled="showType=='detail'" v-model.trim="formData.remark" style="width: 390px"/>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>
<script>
import moment from 'moment'
import {mapGetters, mapState} from 'vuex'
import areaselect from '@/mixins/areaselect'
import deptselect from '@/mixins/deptselect'
import {getMonitorPointNameListByCondition} from "A/monitoring";
import {addLedgerTermiteControl, getLedgerTermiteControlByCondition, modifyLedgerTermiteControl} from "A/businessmanagement";
export default {
  mixins: [areaselect, deptselect],
  props: {
    visible: {
      default: false
    },
    showType: {
      default: 'add'
    },
    detailData: {
      default: null
    }
  },
  data() {
    return {
      moment,
      modalBodyStyle: {
        height: '480px',
        overflowY: 'auto',
      },
      modalVisible: false,
      formData: {
        monitorpointnum: '',
        control_area: '',
        position: '',
        quantity: '',
        requirement: '',
        remark: '',
        create_time:"",
        creator:"",
      },
      //这里面的数据属于必填项
      formRules: {
        monitorpointnum: [{required: true, message: '请选择项目'}],
        control_area: [{required: true, message: '请选择防治区域'}],
        position: [{required: true, message: '请输入位置'}],
        quantity: [{required: true, message: '请输入数量'}],
        requirement: [{required: true, message: '请输入要求'}],
      },
      monitorpointList:[],
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    }),
    modalTitle() {
      if(this.showType == 'add') {
        return '新增'
      }else if(this.showType == 'edit'||this.showType == 'paste') {
        return '修改'
      }else if(this.showType == 'detail') {
        return '详情'
      }else {
        return '';
      }
    },
  },
  watch: {
    visible(val) {
      if(val) {
        this.modalVisible = true;
        this.resetData();
        this.getMonitorPointNameList();
        this.initDetail();
      }
    },
    modalVisible(val) {
      if(!val) {
        this.resetData();
        this.$emit('get-operation-result', 'success', '操作成功');
        this.$emit('update:visible', false);
      }
    },
  },
  created() {
    this.modalVisible = this.visible;
  },
  methods: {
    getMonitorPointNameList(){
      getMonitorPointNameListByCondition({userdepsid:this.userInfo.userdepid}).then(res=>{
        if(res&&res.returncode=='0'){
          this.monitorpointList=res.item
        }
      })
    },
    resetData() {
      if (this.$refs.modalForm) {
        this.$refs.modalForm.resetFields();
      }
    },
    initDetail() {
      if (this.detailData && this.detailData.ledger_id) {
        if(this.showType == 'edit' || this.showType == 'detail' || this.showType === 'paste') {
          this.$nextTick(() => {
            let params = {
              ledger_id: this.detailData.ledger_id
            }
            getLedgerTermiteControlByCondition(params).then(res => {
              if (res && res.returncode == '0') {
                this.formData = res.item[0];
              }
            })
          })
        }
      }
    },
    confirm() {
      if (this.showType == 'add' || this.showType == 'edit' || this.showType === 'paste') {
        this.$refs.modalForm.validate(valid => {
          if (valid) {
            this.formData.create_time=moment(new Date()).format("YYYYMMDDHHmmss")
            this.formData.creator=this.userInfo.username
            let params = {
              ...this.formData,
            };
            if (this.showType == 'add' || this.showType === 'paste') {
              this.showLoading();
              addLedgerTermiteControl(params).then(res => {
                this.hideLoading();
                if (res && res.returncode == '0') {
                  this.$message.success('操作成功');
                  this.$emit('get-operation-result', 'success', '操作成功');
                  this.modalVisible = false;
                } else {
                  this.$message.error(res.errormsg || '操作失败');
                }
              })
            } else if(this.showType == 'edit'){
              this.showLoading();
              modifyLedgerTermiteControl(params).then(res => {
                this.hideLoading();
                if (res && res.returncode == '0') {
                  this.$message.success('操作成功');
                  this.$emit('get-operation-result', 'success', '操作成功');
                  this.modalVisible = false;
                } else {
                  this.$message.error(res.errormsg || '操作失败');
                }
              })
            }else{
              this.$message.warning('请确认信息填写完整')
            }
          } else {
            this.$message.warning('请确认信息填写完整')
            return false;
          }
        })
      } else {
        this.modalVisible = false;
      }
    },
    filterOption(input, option) {
      return (
          option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
  }
}
</script>
<style lang="scss" scoped>
.select-drop-down-platform {
  width: 240px;
}
</style>